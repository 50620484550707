import React from "react"
import { useTranslation } from "react-i18next"

const companyProfileLabels = ["company-name", "address", "president", "executive-officers", "business-start-date", "shareholders", "business-description"]

const companyOverviewLabels = ["company-name", "address", "ceo", "cto", "business-start-date"]

const CompanyProfile = () => {
  const { t } = useTranslation()
  return <section className='flex flex-col gap-8 pl-10 pr-10 pt-16 bg-fade-gradient-bg rounded-3xl'>
    <div className='flex flex-col gap-8'>
      <header>
        <span className='text-2xl'>{t("about-us-page.company-japan.heading.value")}</span>
        <hr className="border border-black w-[6%] mt-2" />
      </header>
      <div className='flex flex-col gap-4 text-xl'>
        {companyProfileLabels.map(label => <p>{t("about-us-page.company-japan." + label + ".label")}{t("about-us-page.company-japan.separator.value")}{t("about-us-page.company-japan." + label + ".value")}</p>)}
      </div>
    </div>

    <div className='flex flex-col gap-8'>
      <header>
        <span className='text-2xl'>{t("about-us-page.company-singapore.heading.value")}</span>
        <hr className="border border-black w-[6%] mt-2" />
      </header>
      <div className='flex flex-col gap-4 text-xl'>
        {companyOverviewLabels.map(label => <p>{t("about-us-page.company-singapore." + label + ".label")}{t("about-us-page.company-singapore.separator.value")}{t("about-us-page.company-singapore." + label + ".value")}</p>)}
      </div>
    </div>

  </section>
}

export default CompanyProfile